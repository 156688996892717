// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/* global ajax_object */

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  function getCat() {
    let dropdown = document.getElementById('cat');
    let category = '-1';
    if (dropdown != null) {
      category = dropdown.options[dropdown.selectedIndex].value;
    }
    return category;
  }

  $('#cat').change(function () {
    let currentPostType = $(this).closest('.ajax-list').data('post-type');
    let ppp = $('.ajax-list').data('per-page');
    filterPosts(getCat(), 1, currentPostType, ppp);
  });

  $(document).on('click', '.ajax-pagination .page-numbers', function (e) {
    e.preventDefault();
    let currentPostType = $('.ajax-list').data('post-type');
    let ppp = $('.ajax-list').data('per-page');
    filterPosts(getCat(), $(this).attr('href'), currentPostType, ppp);
  });

  function filterPosts(
    category = '',
    paged = 1,
    currentPostType = 'post',
    ppp = 10
  ) {
    let tax = $('#cat').data('tax');
    let data = {
      action: 'filter_posts',
      postType: currentPostType,
      category: category,
      paged: paged,
      tax: tax,
      posts_per_page: ppp,
    };
    $('.posts-list-js').fadeOut();
    window.scrollTo({ top: 400, behavior: 'smooth' });
    $.post(ajax_object.ajax_url, data, function (response) {
      if (response) {
        $('.posts-list-js').html(response);
        $('.posts-list-js').addClass('ajax-js');
        $('.ajax-js .ajax-pagination .page-numbers').click(function (e) {
          e.preventDefault();
          let currentPostType = $('.ajax-list').data('post-type');
          let ppp = $('.ajax-list').data('per-page');
          filterPosts(getCat(), $(this).attr('href'), currentPostType, ppp);
        });
        $('.posts-list-js').fadeIn();
      }
    });
  }

  // smooth scroll to an anchor link
  $('a[href^="#"]').on('click', function (event) {
    var target = $(this.getAttribute('href'));
    if (target.length) {
      event.preventDefault();
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: target.offset().top - 110,
          },
          1000
        );
    }
  });

  // Testimonials Slider

  $('.testimonials__slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000,
  });

  $('.header__search-icon').on('click', function () {
    $('.header__search-form').toggleClass('is-active');
    $('body').toggleClass('search-is-open');
  });

  $(document).on('click', function (e) {
    if (
      !$(e.target).closest('.header__search-form, .header__search-icon').length
    ) {
      $('.header__search-form').removeClass('is-active');
      $('body').removeClass('search-is-open');
    }
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  ScrollOut({
    targets: '.text-block__content > *',
    onShown: function (element) {
      const elements = element.parentElement.children;
      Array.from(elements).forEach((el, index) => {
        setTimeout(() => {
          el.setAttribute('data-scroll', 'in');
        }, index * 300);
      });
    },
    once: true,
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('body').toggleClass('burger-is-open');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('body').removeClass('burger-is-open');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
